<div class="studyEndControl">
  <div class="label">Voraussichtliches Studienende</div>
  <div fxLayout="column" fxLayoutGap="0.75rem">
    <div class="selection-wrapper">
      <button class="select-button-chip"
              [ngClass]="{'active': yearControl.value == year}"
              (click)="yearControl.setValue(year)"
              *ngFor="let year of studyEndOptions">{{year}}</button>
    </div>

    <div class="selection-wrapper" *ngIf="showHalfYear && yearControl.value">
      <button class="select-button-chip"
              [ngClass]="{'active': halfYearControl.value == 'JanuaryToJune'}" (click)="halfYearControl.setValue('JanuaryToJune')">
        Januar bis Juni
      </button>
      <button class="select-button-chip"
              [ngClass]="{'active': halfYearControl.value == 'JulyToDecember'}" (click)="halfYearControl.setValue('JulyToDecember')">
        Juli bis Dezember
      </button>
    </div>
  </div>

</div>
