import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SeminarCheckoutS7EntryComponent} from './seminar-checkout-s7-entry/seminar-checkout-s7-entry.component';
import {SeminarCheckoutS7UserComponent} from './seminar-checkout-s7-user/seminar-checkout-s7-user.component';
import {SeminarCheckoutS7MainComponent} from './seminar-checkout-s7-main/seminar-checkout-s7-main.component';
import {SeminarCheckoutS7DatesComponent} from './seminar-checkout-s7-dates/seminar-checkout-s7-dates.component';
import {
  SeminarCheckoutS7LocationComponent
} from './seminar-checkout-s7-location/seminar-checkout-s7-location.component';
import {
  GlobalCheckoutComponentsModule
} from "../../components/global-checkout-components/global-checkout-components.module";
import {RouterModule, RouterOutlet, Routes} from "@angular/router";
import {BrowserModule} from "@angular/platform-browser";
import {ExtendedModule, FlexModule} from "@angular/flex-layout";
import {HidInteractionsModule} from "../../../hid-interactions/hid-interactions.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

const appRoutes: Routes = [
  {path: 'angebote/:routingUrl/checkout/seminars/s7/entry', component: SeminarCheckoutS7EntryComponent},
  {
    path: 'angebote/:routingUrl/checkout/seminars/s7', component: SeminarCheckoutS7MainComponent,
    children: [
      {path: 'location', component: SeminarCheckoutS7LocationComponent},
      {path: 'date', component: SeminarCheckoutS7DatesComponent},
      {path: 'user', component: SeminarCheckoutS7UserComponent}
    ]
  }
]


@NgModule({
  declarations: [
    SeminarCheckoutS7EntryComponent,
    SeminarCheckoutS7UserComponent,
    SeminarCheckoutS7MainComponent,
    SeminarCheckoutS7DatesComponent,
    SeminarCheckoutS7LocationComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(appRoutes),
    BrowserModule,
    FlexModule,
    GlobalCheckoutComponentsModule,
    RouterOutlet,
    HidInteractionsModule,
    ExtendedModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class SeminarCheckoutS7Module { }
