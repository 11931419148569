import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SelectStudyEndYearComponent} from "../select-study-end-year/select-study-end-year.component";
import {FlexLayoutModule, FlexModule} from "@angular/flex-layout";
import {BrowserModule} from "@angular/platform-browser";
import {LocationSelectV1Component} from "../location-select-v1/location-select-v1.component";
import {GlobalModule} from "../../../global/global.module";
import {GeolocationComponent} from "../geolocation/geolocation.component";
import {SuccessBarComponent} from "../success-bar/success-bar.component";
import {HidInteractionsModule} from "../../../hid-interactions/hid-interactions.module";
import {
  SelectPreciseStudyEndYearComponent
} from "../select-precise-study-end-year/select-precise-study-end-year.component";


@NgModule({
  declarations: [
    SelectStudyEndYearComponent,
    LocationSelectV1Component,
    GeolocationComponent,
    SuccessBarComponent,
    SelectPreciseStudyEndYearComponent
  ],
  imports: [
    CommonModule,
    FlexModule, BrowserModule,
    GlobalModule, HidInteractionsModule, FlexLayoutModule
  ],
  exports:[
    SelectStudyEndYearComponent,
    SelectPreciseStudyEndYearComponent,
      LocationSelectV1Component,
    GeolocationComponent,
    SuccessBarComponent,

  ]
})
export class GlobalCheckoutComponentsModule { }
