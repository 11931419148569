import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SeminarCheckoutGatewayComponent} from './seminar-checkout-gateway/seminar-checkout-gateway.component';
import {RouterModule, Routes} from '@angular/router';
import {ClickOutsideDirective} from './click-outside.directive';
import {SeminarCheckoutS6Module} from "./seminar-checkout-s6/seminar-checkout-s6.module";
import {SeminarCheckoutS7Module} from "./seminar-checkout-s7/seminar-checkout-s7.module";


const appRoutes: Routes = [{path: 'angebote/:routingUrl/checkout/seminars/g', component: SeminarCheckoutGatewayComponent}];

@NgModule({
  declarations: [
    SeminarCheckoutGatewayComponent,
    ClickOutsideDirective
  ],
  imports: [
    CommonModule,
    SeminarCheckoutS6Module,
    SeminarCheckoutS7Module,
    RouterModule.forChild(appRoutes)
  ]
})
export class SeminarCheckoutModule { }
