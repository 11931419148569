import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PromotionApiResourceV2} from '../../../generated/cronos/resources';
import {SeminarCheckoutService} from '../seminar-checkout.service';
import {GlobalCheckoutService} from '../../global-checkout.service';
import {BehaviorSubject} from 'rxjs';


@Component({
  selector: 'app-seminar-checkout-gateway',
  templateUrl: './seminar-checkout-gateway.component.html',
  styleUrls: ['./seminar-checkout-gateway.component.scss']
})
export class SeminarCheckoutGatewayComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute,
              private promotionApiv2Resource: PromotionApiResourceV2,
              private seminarCheckoutService: SeminarCheckoutService,
              private globalCheckoutService: GlobalCheckoutService) {
  }


  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {

      let predefinedSeminarId = params['psid'];
      let contactDataAccessToken = params['code'];
      let formatId = params['formatid'];
      let waitingListEntry = params['waitingList'] === "true"

      this.seminarCheckoutService.clearSelectedSeminar();

      // Switch case is used for readability of different scenarios
      switch (true) {
        case (predefinedSeminarId != null):
          this.handlePredefinedSeminar(predefinedSeminarId);
          break;

        case (contactDataAccessToken != null && formatId != null):
          this.handleContactAndFormat(contactDataAccessToken, formatId);
          break;

        case (contactDataAccessToken == null && formatId != null):
          this.handleOnlyFormat(formatId);
          break;

        case (contactDataAccessToken != null && formatId == null):
          this.navigateToError();
          break;

        case (waitingListEntry == true):
          this.handleWaitingList()
          break;

        default:
          this.proceed();
          break;
      }
    });
  }

  handlePredefinedSeminar(predefinedSeminarId: number) {
    this.promotionApiv2Resource.getSeminarDateInfos(predefinedSeminarId).then(data => {
      this.seminarCheckoutService.setSeminar(data);
      this.proceed();
    });
  };

  handleContactAndFormat(contactDataAccessToken: string, formatId: number) {
    Promise.all([
      this.globalCheckoutService.populateContactFromContactDataAccessToken(contactDataAccessToken),
      this.globalCheckoutService.populateWithFormatId(formatId)
    ]).then(() => {
      this.globalCheckoutService.enableForwarding()
      this.proceed();
    });
  };

  handleWaitingList() {
    this.seminarCheckoutService.waitingList = true
    this.globalCheckoutService.enableForwarding()
    this.proceed()
  }

  handleOnlyFormat(formatId: number) {
    this.globalCheckoutService.populateWithFormatId(formatId).then(() => {
      this.proceed();
    });
  };

  navigateToError() {
    this.router.navigate(['error/invalid-link']);
  };

  proceed() {
    let offerUrl = (this.route.params as BehaviorSubject<any>).value['routingUrl'];
    let url = 'angebote/' + offerUrl + '/checkout/seminars/s6/entry';

    // route to s7 with 50 percent chance
    let random = Math.floor(Math.random() * 2);
    if (random == 1) {
      url = 'angebote/' + offerUrl + '/checkout/seminars/s7/entry';
    }

    this.router.navigate([url], {queryParamsHandling: 'preserve'});

  }

}
