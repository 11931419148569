import {Component, OnDestroy, OnInit} from '@angular/core';
import {EnterPromotionContactData, PublicSeminarData} from "../../../../generated/cronos/data";
import {PublicFormatPageData} from "../../../../generated/cms/data";
import {Subject, Subscription} from "rxjs";
import {PromotionApiResourceV2} from "../../../../generated/cronos/resources";
import {GlobalCheckoutService} from "../../../global-checkout.service";
import {SeminarCheckoutService} from "../../seminar-checkout.service";
import {ActivatedRoute, Router} from "@angular/router";
import {InternalCookieService} from "../../../../internal-cookie.service";
import {DateUtilsService} from "../../../../inno-utils/dateutils.service";
import {SeminarCheckoutS7ProcessService} from "../seminar-checkout-s7-process.service";

//if date options are less or equal than this, waitinglist will be offered to the user
const offerAlternativesUnderDatesAmount = 3;

@Component({
  selector: 'app-seminar-checkout-s7-dates',
  templateUrl: './seminar-checkout-s7-dates.component.html',
  styleUrls: ['./seminar-checkout-s7-dates.component.scss']
})
export class SeminarCheckoutS7DatesComponent implements OnInit, OnDestroy {

  user: EnterPromotionContactData;
  dates: PublicSeminarData[] = [];

  offerWaitinglist: boolean = false;
  loading = true;

  page: PublicFormatPageData;
  pageSubscription : Subscription = new Subscription()

  promotionSubject: Subject<PublicSeminarData> = new Subject();
  promotionSubscription: Subscription;
  totalDisplayedSeminarsAmountSubject: Subject<number> = new Subject();
  seminarAmountsSubscription: Subscription;

  constructor(private cronosPromotionResource: PromotionApiResourceV2,
              public globalCheckoutService: GlobalCheckoutService,
              private seminarCheckoutService: SeminarCheckoutService,
              private router: Router,
              private internalCookieService: InternalCookieService,
              private s7ProcessService: SeminarCheckoutS7ProcessService,
              private route: ActivatedRoute,
              private dateUtils: DateUtilsService) {
  }

  ngOnDestroy() {
    if (this.promotionSubscription) {
      this.promotionSubscription.unsubscribe();
    }
    if (this.seminarAmountsSubscription) {
      this.seminarAmountsSubscription.unsubscribe();
    }
    this.pageSubscription?.unsubscribe()
  }

  ngOnInit() {

    this.user = this.globalCheckoutService.getCurrentUser();

    window.scrollTo(0, 0);


    if ((this.globalCheckoutService.forwardingEnabled && this.seminarCheckoutService.getSeminar()?.id) || this.seminarCheckoutService.waitingList) {
      this.reportReached(true, null);
      this.next(true);
    } else {
      this.globalCheckoutService.disableForwarding()
      //this can occur in some situations when the user is coming back from the contactdata-step after having a preselected date and didnt have a location selected because he initially skipped the location step
      if (this.user.locationId == null) {
        this.back();
        return;
      }

      this.pageSubscription = this.globalCheckoutService.currentCheckoutPage.subscribe(next => {
        if (next) {
          this.page = next
          this.loadDates()
        }
      })
    }

  }

  reportReached(skip: boolean, displayedSeminarsAmount?: number) {
    let displayedAmountString = null;
    if (displayedSeminarsAmount) {
      displayedAmountString = displayedSeminarsAmount.toString();
    }
    this.s7ProcessService.reportTrackingEvent('DatesReached',
      {skip: JSON.stringify(skip), numDateOptions: displayedAmountString});
  }



  next(skip: boolean = false) {
    //if step gets skipped, selectDate will not be called so we fire here. Little bit dirty though..
    if (skip) {
      this.s7ProcessService.reportTrackingEvent('DateSelected', {skip: JSON.stringify(skip)});
    }
    this.router.navigate([this.s7ProcessService.prepareSplitRoutingUrl(this.route.parent, 'checkout/seminars/s7/user')]);
  }

  back() {
    this.globalCheckoutService.clearLocation();
    this.router.navigate([this.s7ProcessService.prepareSplitRoutingUrl(this.route.parent, 'checkout/seminars/s7/location')]);
  }

  selectDate(date: PublicSeminarData) {
    this.seminarCheckoutService.setSeminar(date);
    let additionalParams: { [key: string]: string } = {
      promotionId: date.id.toString(),
      promotionDate: this.dateUtils.getDate(date.date),
      promotionTime: this.dateUtils.getTime(date.date),
      promotionWeekday: this.dateUtils.getWeekday(date.date),
      skip: 'false'
    }
    this.s7ProcessService.reportTrackingEvent('DateSelected', additionalParams);
    this.next();
  }

  loadDates() {
    let formatIds = [this.page.cronosFormatId];
    this.cronosPromotionResource.getSeminars({
      locationId: this.user.locationId,
      formatIds: formatIds,
      page: 0,
      pageSize: 200,
      maxSeminarsPerDay: 2
    }).then(page => {
      this.dates = page;
      this.loading = false;
      if (this.dates.length <= offerAlternativesUnderDatesAmount) {
        this.offerWaitinglist = true;
      }
      this.promotionSubscription = this.promotionSubject.subscribe(value => {
        this.selectDate(value);
      });
      this.seminarAmountsSubscription = this.totalDisplayedSeminarsAmountSubject.subscribe(value => {
        this.reportReached(false, value);
      });
      let existingSubscription = this.internalCookieService.getExistingSeminarRegistration(this.page.cronosFormatId);
      if (existingSubscription.length > 0) {
        this.cronosPromotionResource.getAllowanceForMultipleRegistrationsForLocationAndSeminar(
          {locationId: this.user.locationId, formatId: this.page.cronosFormatId}
        ).then(result => {
          if (result.value == false) {
            this.dates = [];
          }
        });

      }

    });
  }

  enableWaitingList() {
    this.seminarCheckoutService.waitingList = true;
    this.next();
  }

}
