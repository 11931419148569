import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-select-precise-study-end-year',
  templateUrl: './select-precise-study-end-year.component.html',
  styleUrls: ['./select-precise-study-end-year.component.scss']
})
export class SelectPreciseStudyEndYearComponent implements OnInit, OnDestroy {

  @Input() yearControl = new FormControl()
  @Input() halfYearControl = new FormControl()

  currentYear: number = new Date().getFullYear()
  showHalfYear: boolean = true

  studyEndOptions = []

  yearSubscription: Subscription


  constructor() {

  }

  ngOnInit(): void {
    this.yearSubscription = this.yearControl.valueChanges.subscribe(year => {
      this.setShowHalfYear(year)
    })

    this.setShowHalfYear(this.yearControl.value)
    this.initStudyEndOptions()
  }

  ngOnDestroy() {
    this.yearSubscription.unsubscribe()
  }

  initStudyEndOptions() {
    this.studyEndOptions.push(this.currentYear, this.currentYear + 1, this.currentYear + 2, this.currentYear + 3, this.currentYear + 4)
  }

  setShowHalfYear(year: number) {
    this.showHalfYear = year <= this.currentYear + 1;
    if (!this.showHalfYear) {
      this.halfYearControl.setValue(null)
    } else {
      this.halfYearControl.setValue('JanuaryToJune')
    }
  }

}
