import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {GlobalCheckoutService} from "../../../global-checkout.service";
import {SeminarCheckoutS7ProcessService} from "../seminar-checkout-s7-process.service";

@Component({
  selector: 'app-seminar-checkout-s7-entry',
  templateUrl: './seminar-checkout-s7-entry.component.html',
  styleUrls: ['./seminar-checkout-s7-entry.component.scss']
})
export class SeminarCheckoutS7EntryComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute,
              private s7ProcessService: SeminarCheckoutS7ProcessService,
              private globalCheckoutService: GlobalCheckoutService) { }

  ngOnInit(): void {
    //route to first step and set forwarding to enabled for initial forwarding
    this.router.navigate([this.s7ProcessService.prepareSplitRoutingUrl(this.route, '/checkout/seminars/s7/location')]);
    this.globalCheckoutService.enableForwarding()
  }


}
